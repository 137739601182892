@import "../node_modules/antd/dist/antd.min.css";

body {
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  float: left;
  width: 120px;
  img {
    max-width: 100%;
    max-height: 50px;
  }
}

.site-layout-background {
  background: #fff;
}

.header {
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
}

.footer {
  background: #fff;
  text-align: center;
}

.content {
  padding: 50px;
  min-height: calc(100vh - 134px);
  .content-inner {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    .content-row {
      min-height: 100%;
    }
  }
}

.qr-container {
  margin-left: 12px;
  background: #fff;
  position: sticky;
  top: 0;
  padding: 25px 12px;
  border-radius: 5px;
}

.form-section {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 12px;
  .ant-divider-horizontal.ant-divider-with-text-left:before {
    display: none;
  }
  .ant-divider-inner-text {
    padding-left: 0;
    font-weight: 600;
  }
}

.button-area {
  button {
    margin-right: 10px;
  }
}

.ant-input-group.ant-input-group-compact {
  display: flex;
}

.country_code_selector {
  width: 150px !important;
}

.desktop .form-col,
.tablet .form-col {
  padding-right: 10px;
}
.qr-output-area {
  overflow: hidden;
  .canvas-wrapper {
    display: none;
  }
  &.has-data {
    text-align: center;
    .canvas-wrapper {
      display: inline-block;
      transform-origin: top left;
    }
  }
}
